import { render, staticRenderFns } from "./SemPermissao.vue?vue&type=template&id=43ac6bb9&scoped=true&"
import script from "./SemPermissao.vue?vue&type=script&lang=js&"
export * from "./SemPermissao.vue?vue&type=script&lang=js&"
import style0 from "./SemPermissao.vue?vue&type=style&index=0&id=43ac6bb9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43ac6bb9",
  null
  
)

export default component.exports