<template>
  <Panel lineColor="var(--danger-color)">
    <div class="no-permission">
      <img src="@/assets/img/locked.svg" alt="Sem permissão" />
      <h1>Sem permissão</h1>
      <p>Você não tem acesso a esta área do portal</p>
      <p>
        Se acredita que isto é um engano, entre em contato com o administrador
        do LifeApps Omnichannel na sua empresa e peça acesso às lojas e itens de
        que necessita.
      </p>
    </div>
  </Panel>
</template>

<script>
import Panel from '@/components/Panel'
export default {
  name: 'Permissao',
  components: { Panel }
}
</script>

<style lang="scss" scoped>
.no-permission {
  min-height: 300px;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #6e7073;
  img {
    margin: 10px 0;
    max-width: 100px;
    max-height: 100px;
    width: auto;
    height: auto;
  }
}
</style>
